import Component from '../../libs/component';
import scope from '../../scope';

scope.components['testimonials'] = Component.extend({
    name: 'testimonials',
    ui: {
        list: '.testimonials__list '
    },
    events: {
        'initialize.carousel @ui.list': 'initCarousel'
    },
    initCarousel: function(e, data){
        data.options.autoPlay = 30000;
    }
});

export default scope.components['testimonials'];

